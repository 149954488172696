@tailwind base;
@tailwind components;

@font-face {
  font-family: 'BuenosAiresWeb';
  src: url('./assets/fonts/BuenosAiresWeb-ThinItalic.woff2')
      format('woff2'),
    url('./assets/fonts/BuenosAiresWeb-ThinItalic.woff2')
      format('woff');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'BuenosAiresWeb';
  src: url('./assets/fonts/BuenosAiresWeb-Thin.woff2') format('woff2'),
    url('./assets/fonts/BuenosAiresWeb-Thin.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'BuenosAiresWeb';
  src: url('./assets/fonts/BuenosAiresWeb-LightItalic.woff2')
      format('woff2'),
    url('./assets/fonts/BuenosAiresWeb-LightItalic.woff')
      format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'BuenosAiresWeb';
  src: url('./assets/fonts/BuenosAiresWeb-Light.woff2')
      format('woff2'),
    url('./assets/fonts/BuenosAiresWeb-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'BuenosAiresWeb';
  src: url('./assets/fonts/BuenosAiresWeb-RegularItalic.woff2')
      format('woff2'),
    url('./assets/fonts/BuenosAiresWeb-RegularItalic.woff')
      format('woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'BuenosAiresWeb';
  src: url('./assets/fonts/BuenosAiresWeb-Regular.woff2')
      format('woff2'),
    url('./assets/fonts/BuenosAiresWeb-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'BuenosAiresWeb';
  src: url('./assets/fonts/BuenosAiresWeb-SemiBoldItalic.woff2')
      format('woff2'),
    url('./assets/fonts/BuenosAiresWeb-SemiBoldItalic.woff')
      format('woff');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'BuenosAiresWeb';
  src: url('./assets/fonts/BuenosAiresWeb-SemiBold.woff2')
      format('woff2'),
    url('./assets/fonts/BuenosAiresWeb-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'BuenosAiresWeb';
  src: url('./assets/fonts/BuenosAiresWeb-BoldItalic.woff2')
      format('woff2'),
    url('./assets/fonts/BuenosAiresWeb-BoldItalic.woff')
      format('woff');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'BuenosAiresWeb';
  src: url('./assets/fonts/BuenosAiresWeb-Bold.woff2') format('woff2'),
    url('./assets/fonts/BuenosAiresWeb-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'BuenosAiresWeb';
  src: url('./assets/fonts/BuenosAiresWeb-BlackItalic.woff2')
      format('woff2'),
    url('./assets/fonts/BuenosAiresWeb-BlackItalic.woff')
      format('woff');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'BuenosAiresWeb';
  src: url('./assets/fonts/BuenosAiresWeb-Black.woff2')
      format('woff2'),
    url('./assets/fonts/BuenosAiresWeb-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'BuenosAiresWeb';
  src: url('./assets/fonts/BuenosAiresWeb-BoldItalic.woff2')
      format('woff2'),
    url('./assets/fonts/BuenosAiresWeb-BoldItalic.woff')
      format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'BuenosAiresWeb';
  src: url('./assets/fonts/BuenosAiresWeb-Bold.woff2') format('woff2'),
    url('./assets/fonts/BuenosAiresWeb-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'BuenosAiresWeb';
  src: url('./assets/fonts/BuenosAiresWeb-RegularItalic.woff2')
      format('woff2'),
    url('./assets/fonts/BuenosAiresWeb-RegularItalic.woff')
      format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'BuenosAiresWeb';
  src: url('./assets/fonts/BuenosAiresWeb-Regular.woff2')
      format('woff2'),
    url('./assets/fonts/BuenosAiresWeb-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@tailwind utilities;
